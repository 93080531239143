<template>
    <vx-card :title="'Work Order Execution - Actual Picking'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Work Order ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="woCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Work Order Description</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="description" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Bill Of Material ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="bomCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="warehouseName" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table table-bordered">
                    <thead class="vs-table--thead">
                        <tr>
                            <th style="vertical-align: middle;" rowspan="2">SKU Code</th>
                            <th style="vertical-align: middle;" rowspan="2">SKU Description</th>
                            <th style="vertical-align: middle; width: 7%" rowspan="2">HU</th>
                            <th style="vertical-align: middle; width: 5%" rowspan="2">Qty</th>
                            <th style="text-align: center;" colspan="5">Suggestion</th>
                            <th style="text-align: center;" colspan="5">Actual</th>
                        </tr>
                        <tr>
                            <th style="vertical-align: middle; text-align: center; width: 7%">HU</th>
                            <th style="vertical-align: middle; text-align: center; width: 5%">Qty</th>
                            <th style="vertical-align: middle; text-align: center">Storage Area</th>
                            <th style="vertical-align: middle; text-align: center">Batch Number</th>
                            <th style="vertical-align: middle; text-align: center">Expired Date</th>
                            <th style="vertical-align: middle; text-align: center; width: 7%">HU</th>
                            <th style="vertical-align: middle; text-align: center; width: 5%">Qty</th>
                            <th style="vertical-align: middle; text-align: center; width: 12%">Storage Area</th>
                            <th style="vertical-align: middle; text-align: center; width: 8%">Batch Number #</th>
                            <th style="vertical-align: middle; text-align: center">Expired Date</th>
                            <th style="vertical-align: middle; text-align: center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(tr, index) in skuLines">
                            <!-- item line -->
                            <tr style="padding-bottom: 5px;" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="firstRowSpan(index)">
                                    <vs-input v-model="tr.sku_code" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="firstRowSpan(index)">
                                    <vs-input v-model="tr.item_name" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="firstRowSpan(index)">
                                    <vs-input v-model="tr.unit" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;" :rowspan="firstRowSpan(index)">
                                    <vs-input v-model="tr.qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>

                                <!-- first suggestion line -->
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.suggestion_line[0].unit" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input type="number" v-model="tr.suggestion_line[0].qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.suggestion_line[0].storage_code" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input v-model="tr.suggestion_line[0].batch" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="formatDate(tr.suggestion_line[0].exp_date)" :readonly="true" style="width: auto;"></vs-input>
                                </td>

                                <!-- first actual lines -->
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="tr.option_hu"
                                        v-model="tr.actual_lines[0].selected_hu"
                                        @input="(val) => { onChangeHu(val, index, 0); }"
                                        class="w-full"
                                    />
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input
                                        type="number"
                                        class="w-full"
                                        v-model="tr.actual_lines[0].qty"
                                        @input="(val) => { onChangeQty(val, index, 0); }">
                                    </vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="tr.option_warehouse_area"
                                        v-model="tr.actual_lines[0].storage_area"
                                        @input="(val) => { onChangeStorage(val, index, 0); }"
                                        class="w-full"
                                        label="WarehouseAreaCode"
                                    />
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="tr.option_batch"
                                        :value="tr.actual_lines[0].batch_number"
                                        @input="(val) => { onChangeBatch(val, index, 0); }"
                                        class="w-full"
                                        label="Batch"
                                    />
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :options="tr.option_exp_date"
                                        :value="formatDate(tr.actual_lines[0].expired_date)"
                                        @input="(val) => { onChangeExpDate(val, index, 0); }"
                                        class="w-full"
                                        label="ExpiredDate"
                                    >
                                        <template slot="option" slot-scope="option">
                                            {{ formatDate(option.ExpiredDate) }}
                                        </template>
                                    </v-select>
                                </td>
                                <td class="td vs-table--td">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button v-if="skuLines[index].actual_lines.length > 1"
                                            @click.stop="removeRow(index, 0)" size="small" color="danger"
                                            icon-pack="feather" icon="icon-trash" title="Remove Row"
                                            style="margin-right: 5px" />
                                        <vs-button @click.stop="addRow(index)" size="small" color="success"
                                            icon-pack="feather" icon="icon-plus" title="Add Row" />
                                    </div>
                                </td>
                            </tr>

                            <!-- added line -->
                            <template v-for="idx in skuLines[index].suggestion_line.length > skuLines[index].actual_lines.length ? skuLines[index].suggestion_line.length : skuLines[index].actual_lines.length">
                                <tr style="padding-bottom: 5px" :key="index + '-' + idx" v-if="idx > 0">
                                    <!-- item suggestion -->
                                    <template v-if="idx < skuLines[index].suggestion_line.length">
                                        <td class="td vs-table--td align-top" style="padding: 5px;">
                                            <vs-input v-model="tr.suggestion_line[idx].unit" :readonly="true" style="width: auto;"></vs-input>
                                        </td>
                                        <td class="td vs-table--td align-top" style="padding: 5px;">
                                            <vs-input type="number" v-model="tr.suggestion_line[idx].qty" :readonly="true" style="width: auto;"></vs-input>
                                        </td>
                                        <td class="td vs-table--td align-top" style="padding: 5px;">
                                            <vs-input v-model="tr.suggestion_line[idx].storage_code" :readonly="true" style="width: auto;"></vs-input>
                                        </td>
                                        <td class="td vs-table--td align-top" style="padding: 5px;">
                                            <vs-input v-model="tr.suggestion_line[idx].batch" :readonly="true" style="width: auto;"></vs-input>
                                        </td>
                                        <td class="td vs-table--td align-top" style="padding: 5px;">
                                            <vs-input :value="formatDate(tr.suggestion_line[idx].exp_date)" :readonly="true" style="width: auto;"></vs-input>
                                        </td>
                                    </template>

                                    <template v-if="idx == skuLines[index].suggestion_line.length && skuLines[index].suggestion_line.length < skuLines[index].actual_lines.length">
                                        <td class="td vs-table--td align-top" style="padding: 5px;"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                        <td class="td vs-table--td align-top" style="padding: 5px;"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                        <td class="td vs-table--td align-top" style="padding: 5px;"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                        <td class="td vs-table--td align-top" style="padding: 5px;"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                        <td class="td vs-table--td align-top" style="padding: 5px;"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                    </template>

                                    <!-- actual picking -->
                                    <template v-if="idx < skuLines[index].actual_lines.length">
                                        <td class="td vs-table--td" style="padding: 5px">
                                            <v-select
                                                :filterable="false"
                                                :options="tr.option_hu"
                                                v-model="tr.actual_lines[idx].selected_hu"
                                                @input="(val) => { onChangeHu(val, index, idx); }"
                                                class="w-full"
                                            />
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            <vs-input
                                                type="number"
                                                class="w-full"
                                                v-model="tr.actual_lines[idx].qty"
                                                @input="(val) => { onChangeQty(val, index, idx); }">
                                            </vs-input>
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            <v-select
                                                :filterable="false"
                                                :options="tr.option_warehouse_area"
                                                v-model="tr.actual_lines[idx].storage_area"
                                                @input="(val) => { onChangeStorage(val, index, idx); }"
                                                class="w-full"
                                                label="WarehouseAreaCode"
                                            />
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            <v-select
                                                :filterable="false"
                                                :options="tr.option_batch"
                                                :value="tr.actual_lines[idx].batch_number"
                                                @input="(val) => { onChangeBatch(val, index, idx); }"
                                                class="w-full"
                                                label="Batch"
                                            />
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            <v-select
                                                :filterable="false"
                                                :options="tr.option_exp_date"
                                                :value="formatDate(tr.actual_lines[idx].expired_date)"
                                                @input="(val) => { onChangeExpDate(val, index, idx); }"
                                                class="w-full"
                                                label="ExpiredDate"
                                            >
                                                <template slot="option" slot-scope="option">
                                                    {{ formatDate(option.ExpiredDate) }}
                                                </template>
                                            </v-select>
                                        </td>
                                        <td class="td vs-table--td">
                                            <div class="vx-input-group flex mx-auto">
                                                <vs-button v-if="skuLines[index].actual_lines.length > 1"
                                                    @click.stop="removeRow(index, idx)" size="small" color="danger"
                                                    icon-pack="feather" icon="icon-trash" title="Remove Row" />
                                            </div>
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" color="primary" v-on:click="handleSave">Save</vs-button>
                    <vs-button class="ml-2 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";
import Swal from 'sweetalert2'

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            id: null,
            readonly: true,
            status: null,
            woCode: null,
            description: null,
            bomCode: null,
            warehouseID: 0,
            warehouseName: null,
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    option_hu: [],
                    option_warehouse_area: [],
                    option_batch: [],
                    option_exp_date: [],
                    unit: null,
                    qty: 0,
                    suggestion_line: [
                        {
                            suggestion_id: 0,
                            item_unit_id: 0,
                            sku_code: null,
                            item_name: null,
                            unit: null,
                            qty: 0,
                            storage_code: null,
                            batch: null,
                            exp_date: null,
                        }
                    ],
                    actual_lines: [
                        {
                            item_stock_id: 0,
                            selected_hu: null,
                            qty: 0,
                            storage_area: null,
                            batch_number: null,
                            expired_date: null,
                        },
                    ],
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            // console.log(this.id)
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/work-order-execution/picking-form/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.woCode = resp.data.wo_code
                        this.description = resp.data.wo_description
                        this.bomCode = resp.data.bom_code
                        this.warehouseID = resp.data.warehouse_id
                        this.warehouseName = resp.data.warehouse_name
                        this.skuLines = resp.data.sku_lines
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleSave() {
            let message = "";
            let skus = [];
            this.skuLines.forEach((val) => {
                let actLines = [];

                val.actual_lines.forEach((valActLine) => {
                    if (valActLine.selected_hu == null) {
                        message = "please check hu, hu is required";
                        return;
                    }

                    if (valActLine.qty < 1) {
                        message = "qty is required more than zero";
                        return;
                    }

                    if (valActLine.storage_area == null) {
                        message = "please select storage area";
                        return;
                    }

                    if (valActLine.batch_number == null) {
                        message = "please select batch number";
                        return;
                    }

                    if (valActLine.expired_date == null) {
                        message = "please select expired date";
                        return;
                    }

                    actLines.push({
                        item_stock_id: valActLine.item_stock_id == null ? 0 : valActLine.item_stock_id,
                        selected_hu: valActLine.selected_hu,
                        qty: parseInt(valActLine.qty),
                        storage_area: (valActLine.storage_area.WarehouseAreaCode) ? valActLine.storage_area.WarehouseAreaCode : valActLine.storage_area,
                        batch_number: (valActLine.batch_number.Batch) ? valActLine.batch_number.Batch : valActLine.batch_number,
                        expired_date: valActLine.expired_date,
                    });
                });

                skus.push({
                    sku_code: val.sku_code,
                    actual_lines: actLines,
                });
            });

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let formData = new FormData();
            formData.append("skus", JSON.stringify(skus));

            this.$vs.loading();
            this.$http
                .post("/api/wms/v1/work-order-execution/check-stock", formData)
                .then((resp) => {
                    if(resp.code == 200) {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: 'All stock available!',
                            text: "Are you sure want to submit form?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'SUBMIT'
                        }).then((result) => {
                            if(result.isConfirmed) {
                                this.$vs.loading();
                                this.$http
                                    .put("/api/wms/v1/work-order-execution/save-actual/" + this.id, formData)
                                    .then(resp => {
                                        if (resp.code == 200) {
                                            this.$vs.loading.close();
                                            Swal.fire(
                                                'Done!',
                                                'Items has been updated',
                                                'success'
                                            )
                                            this.draw++;
                                            this.handleBack()
                                        } else {
                                            this.$vs.loading.close();
                                            Swal.fire(
                                                'Something gone wrong', 
                                                'ERROR : UNKNOWN', 
                                                'info'
                                            )
                                        }

                                    })
                                    .catch((error) => {
                                        this.$vs.loading.close();
                                        console.log(error);
                                    });
                            }
                        })
                    } else {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: 'Stock is less than required!',
                            text: resp.message,
                            icon: 'error',
                        });
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });

            // this.$vs.dialog({
            //     type: "confirm",
            //     color: "danger",
            //     title: `Confirm`,
            //     text: "Are you sure to save this data?",
            //     accept: () => {
            //         this.$vs.loading();
            //         this.$http
            //             .put("/api/wms/v1/work-order-execution/save-actual/" + this.id, formData)
            //             .then((resp) => {
            //                 if (resp.code == 200) {
            //                     this.$vs.notify({
            //                         color: "success",
            //                         title: "Success",
            //                         text: resp.message,
            //                         position: "top-right",
            //                         iconPack: "feather",
            //                         icon: "icon-check-circle",
            //                     });
            //                     this.$vs.loading.close();
            //                     // this.handleBack()
            //                 } else {
            //                     this.$vs.loading.close();
            //                     this.$vs.notify({
            //                         color: "danger",
            //                         title: "Error",
            //                         text: resp.message,
            //                         position: "top-right",
            //                         iconPack: "feather",
            //                         icon: "icon-x-circle",
            //                     });
            //                 }
            //             })
            //             .catch((error) => {
            //                 this.$vs.loading.close();
            //                 console.log(error);
            //             });
            //     }
            // });
        },
        handleBack() {
            this.$router.push({
                name: "work-order-execution",
            });
        },
        onChangeHu(val, index, idx) {
            this.$vs.loading();
            this.action = "selected_hu"
            this.skuLines[index].actual_lines[idx].selected_hu = val
            if (this.skuLines[index].actual_lines[idx].selected_hu) {
                this.$http
                    .get("/api/wms/v1/work-order-execution/get-dropdown-opt/" + this.action, {
                        params: {
                            sku_code: this.skuLines[index].sku_code,
                            selected_hu: this.skuLines[index].actual_lines[idx].selected_hu,
                            warehouse_id: this.warehouseID,
                        },
                    })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$set(this.skuLines[index], 'option_warehouse_area', resp.data.storage_option);
                        }
                        this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
            }
        },
        onChangeQty(val, index, idx) {
            this.skuLines[index].actual_lines[idx].qty = val
            if (this.skuLines[index].actual_lines[idx].qty < 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Quantity need more than zero",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.skuLines[index].actual_lines[idx].qty = 0
            }
        },
        onChangeStorage(val, index, idx) {
            this.$vs.loading();
            this.action = "selected_storage"
            this.skuLines[index].actual_lines[idx].storage_area = val
            if (this.skuLines[index].actual_lines[idx].storage_area) {
                this.$http
                    .get("/api/wms/v1/work-order-execution/get-dropdown-opt/" + this.action, {
                        params: {
                            sku_code: this.skuLines[index].sku_code,
                            selected_hu: this.skuLines[index].actual_lines[idx].selected_hu,
                            warehouse_area_id: this.skuLines[index].actual_lines[idx].storage_area.WarehouseAreaID,
                            warehouse_id: this.warehouseID,
                        },
                    })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$set(this.skuLines[index], 'option_batch', resp.data.storage_option);
                        }
                        this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
            } else if (this.skuLines[index].actual_lines[idx].storage_area === null) {
                this.$vs.loading.close();
            }
        },
        onChangeBatch(val, index, idx) {
            // const newBatchNumber = val
            // console.log("selected batch : ", newBatchNumber.Batch)

            // this.skuLines.forEach((val) => {
            //     for (let i = 0; i < val.actual_lines.length; i++) {
            //         if (val.actual_lines[i].batch_number === newBatchNumber) {
            //             console.log("batch sudah terpilih!!")
            //             this.$vs.notify({
            //                 color: "danger",
            //                 title: "Error",
            //                 text: "Similar batch already picked in another row",
            //                 position: "top-right",
            //                 iconPack: "feather",
            //                 icon: "icon-x-circle",
            //             });
            //             this.skuLines[index].actual_lines[idx].batch_number = null;

            //             return;
            //         } else {
            //             this.skuLines[index].actual_lines[idx].batch_number = val
            //             this.displayBatch(this.skuLines[index].actual_lines[idx].batch_number, index, idx);
            //         }
            //     }
            // });

            this.$vs.loading();
            this.action = "selected_batch"
            this.skuLines[index].actual_lines[idx].batch_number = val
            if (this.skuLines[index].actual_lines[idx].batch_number) {
                this.$http
                    .get("/api/wms/v1/work-order-execution/get-dropdown-opt/" + this.action, {
                        params: {
                            sku_code: this.skuLines[index].sku_code,
                            selected_hu: this.skuLines[index].actual_lines[idx].selected_hu,
                            warehouse_area_id: this.skuLines[index].actual_lines[idx].storage_area.WarehouseAreaID,
                            batch: this.skuLines[index].actual_lines[idx].batch_number.Batch,
                            warehouse_id: this.warehouseID,
                        },
                    })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$set(this.skuLines[index], 'option_exp_date', resp.data.storage_option);
                        }
                        this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
            }
        },
        displayBatch(val, index, idx) {
            this.$vs.loading();
            this.action = "selected_batch"
            this.skuLines[index].actual_lines[idx].batch_number = val
            if (this.skuLines[index].actual_lines[idx].batch_number) {
                this.$http
                    .get("/api/wms/v1/work-order-execution/get-dropdown-opt/" + this.action, {
                        params: {
                            sku_code: this.skuLines[index].sku_code,
                            selected_hu: this.skuLines[index].actual_lines[idx].selected_hu,
                            warehouse_area_id: this.skuLines[index].actual_lines[idx].storage_area.WarehouseAreaID,
                            batch: this.skuLines[index].actual_lines[idx].batch_number.Batch,
                            warehouse_id: this.warehouseID,
                        },
                    })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$set(this.skuLines[index], 'option_exp_date', resp.data.storage_option);
                        }
                        this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
            }
        },
        onChangeExpDate(val, index, idx) {
            this.skuLines[index].actual_lines[idx].expired_date = val.ExpiredDate
        },
        removeRow(index, idx) {
            this.skuLines[index].actual_lines.splice(idx, 1);
        },
        addRow(index) {
            this.skuLines[index].actual_lines.push({
                item_stock_id: 0,
                selected_hu: null,
                qty: 0,
                storage_area: null,
                batch_number: null,
                expired_date: null,
            });
        },
        calculateRowSpan(index, idx) {
            let suggestionRowCount = this.skuLines[index].suggestion_line.length;
            let actualRowCount = this.skuLines[index].actual_lines.length;

            if (suggestionRowCount < actualRowCount) {
                return actualRowCount - suggestionRowCount == 0
                    ? null
                    : actualRowCount - suggestionRowCount;
            }

            if (this.skuLines[index].suggestion_line.length == 2) {
                let lastSuggestionIndex = suggestionRowCount > 0 ? suggestionRowCount - 1 : 0;
                let startActualIndex = lastSuggestionIndex;
                let totalRows = Math.max(suggestionRowCount, actualRowCount + startActualIndex);

                return totalRows;
            } else {
                return null;
            }
        },
        firstRowSpan(index) {
            return this.skuLines[index].suggestion_line.length >
                this.skuLines[index].actual_lines.length
                ? this.skuLines[index].suggestion_line.length
                : this.skuLines[index].actual_lines.length;
        },
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>